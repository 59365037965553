import { createBrowserRouter, RouterProvider } from "react-router-dom"
// import Soon from "./pages/Soon/Soon"
import Student from "./pages/Student/Student"
import Stats from "./pages/Stats/Stats"
import Teacher from "./pages/Teacher/Teacher"

const App = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      Component: Student,
    },
    {
      path: "/student",
      Component: Student,
    },
    {
      path: "/teacher",
      Component: Teacher,
    },
    {
      path: "/stats",
      Component: Stats,
    },
  ])

  return <RouterProvider router={router} />
}

export default App
