import { useEffect } from "react"
import "./Student.styles.css"
import { downloadApp } from "../../utils/download"
import { STUDENT_APP_ANDROID, STUDENT_APP_IOS } from "../../consts"

const Student = () => {
  useEffect(() => {
    downloadApp("student")
  }, [])

  return (
    <div className="root">
      {/* <h1 className="title"></h1> */}
      <div className="header">
        <img src="/white-logo.svg" className="logo" />
        <h1>הורידו עכשיו</h1>
      </div>
      <div className="badges">
        <a href={STUDENT_APP_IOS} target="_blank">
          <img src="/stores/app-store.svg" />
        </a>
        <a href={STUDENT_APP_ANDROID} target="_blank">
          <img src="/stores/play-store.svg" />
        </a>
      </div>
    </div>
  )
}
export default Student
