import { useEffect, useState } from "react"
import "./Stats.styles.css"

const Stats = () => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [events, setEvents] = useState(0)
  const [liveEvents, setLiveEvents] = useState(0)
  const [liveTests, setLiveTests] = useState(0)
  const [students, setStudents] = useState(0)
  const [teachers, setTeachers] = useState(0)

  const fetchStats = async () => {
    try {
      const res = await fetch("https://api.drivewithdio.com/stats")
      const data = await res.json()
      setEvents(data.events)
      setStudents(data.students)
      setTeachers(data.teachers)
      setLiveEvents(data.liveEvents)
      setLiveTests(data.liveTests)
    } catch (err) {
      setError(true)
    }
    setLoading(false)
  }
  useEffect(() => {
    fetchStats()
  }, [])

  return (
    <div className="stats">
      {loading ? (
        <h1>טוען</h1>
      ) : error ? (
        <>
          <h1>שגיאה</h1>
        </>
      ) : (
        <>
          <div className="header">
            <img src="/white-logo.svg" className="logo" />
            <h1>נתונים</h1>
          </div>
          <div className="boxes">
            <div>
              <span>שיעורים</span>
              <h3>{events}</h3>
            </div>
            <div>
              <span>תלמידים</span>
              <h3>{students}</h3>
            </div>
            <div>
              <span>מורים</span>
              <h3>{teachers}</h3>
            </div>
          </div>
          <h1>לייב</h1>
          <div className="boxes">
            <div>
              <span>שיעורים</span>
              <h3>{liveEvents}</h3>
            </div>
            <div>
              <span>טסטים</span>
              <h3>{liveTests}</h3>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
export default Stats
