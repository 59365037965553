import {
  STUDENT_APP_ANDROID,
  STUDENT_APP_IOS,
  TEACHER_APP_ANDROID,
  TEACHER_APP_IOS,
} from "../consts"
import { getMobileOperatingSystem } from "./fingerprints"

type AppType = "student" | "teacher"

export const downloadApp = (app: AppType) => {
  const linkMapping: Record<AppType, Partial<Record<Platforms, string>>> = {
    student: {
      Android: STUDENT_APP_ANDROID,
      iOS: STUDENT_APP_IOS,
    },
    teacher: {
      Android: TEACHER_APP_ANDROID,
      iOS: TEACHER_APP_IOS,
    },
  }

  const appLinks = linkMapping[app]

  const os = getMobileOperatingSystem()

  const link = appLinks[os]

  if (!link) {
    return
  }

  window.location.replace(link)
}
