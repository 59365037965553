export const getMobileOperatingSystem = (): Platforms => {
  const userAgent =
    navigator.userAgent ||
    navigator.vendor ||
    // @ts-ignore
    window.opera

  if (/android/i.test(userAgent)) {
    return "Android"
  }

  // @ts-ignore
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS"
  }

  return "Other"
}
